import Api from '@/axios'

export default {
  getContactInfo () {
    return Api().get('pages/contacts')
  },
  sendMessage (payload) {
    return Api().post('pages/contacts/store', payload)
  },
  getHiringData () {
    return Api().get('pages/contacts/hire/form')
  },
  getSpacificHiringData (id) {
    return Api().get(`pages/contacts/hire/form/${id}`)
  },
  sendMyData (formId, payload) {
    const options = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return Api().post(`pages/contacts/hire/store/${formId}`, payload, options)
  }
}
